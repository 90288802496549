import * as React from "react";
import "./navbar.scss";
import * as logo from "../../assets/img/wyhn.svg";
import * as logotxt from "../../assets/img/wyhn_text_blk.svg";

import { Link, withRouter } from "react-router-dom";
import { PAGES } from "../../App";

import i18n from "../../i18n";
import { withNamespaces, WithNamespaces } from "react-i18next";

declare var M: any;
declare var $: any;

export interface NavbarProps extends WithNamespaces {
    location?: any; // RouteComponentProps
}

interface NavbarStates {
    isOnTop: boolean;
    theme: string;
    sideNav?: any;
}

@(withRouter as any)
class Navbar extends React.Component<NavbarProps, NavbarStates> {
    constructor(props) {
        super(props);

        this.state = {
            isOnTop: true,
            theme: this.getNavBarColors(this.props.location.pathname)
        };
    }

    componentDidUpdate(prevProps) {
        // will be true
        if (this.props.location !== prevProps.location) {
            this.setState({
                theme: this.getNavBarColors(this.props.location.pathname)
            });
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", e => this.handleScroll(e));
        this.setState({
            sideNav: M.Sidenav.init(document.querySelectorAll(".sidenav"), {})[0]
        });

        M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {});
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", e => this.handleScroll(e));
    }

    handleScroll(_event) {
        let scrollTop = document.documentElement.scrollTop;
        this.setState({
            isOnTop: scrollTop <= 20
        });
    }

    getNavBarColors(location: string): string {
        switch (location) {
            case PAGES.TRANSPORTERS:
                return "theme--wyhn";
            case PAGES.PUBLICITY:
            case PAGES.MOBILE_APP:
            case PAGES.MAIN:
                return "theme--white";
            default:
                return "theme--black";
        }
    }

    static renderPageLinks(t: i18n.TranslationFunction<any, object, string>, onClick?: () => void, showLang = true) {
        const isFR = i18n.language === "fr";
        return [
            showLang ? (
                <li className="item--lang" key="link-4" onClick={() => i18n.changeLanguage(isFR ? "en" : "fr")}>
                    <a className="item">{isFR ? "English" : "Français"}</a>
                </li>
            ) : (
                []
            )
        ];
    }

    render() {
        const closeSideNav = () => {
            if (this.state.sideNav) {
                this.state.sideNav.close();
            }
        };

        const { t } = this.props;

        return (
            <div className="navbar-fixed">
                <nav className={this.state.theme + (this.state.isOnTop ? "" : " scrolled")}>
                    <div className="nav-wrapper container">
                        <a href="#" data-target="sidenav" className="sidenav-trigger">
                            <i className="material-icons">menu</i>
                        </a>
                        <Link className="title" to={PAGES.MAIN}>
                            <img className="sidenav__logo left" src={logo} />
                            <img className="sidenav__logo_txt" src={logotxt} />
                        </Link>
                        <ul className="right hide-on-med-and-down">{Navbar.renderPageLinks(t)}</ul>
                    </div>
                </nav>
                <ul className="sidenav" id="sidenav">
                    <li>
                        <Link className="sidenav__title" to={PAGES.MAIN} onClick={closeSideNav}>
                            Wyhn
                        </Link>
                    </li>
                    {Navbar.renderPageLinks(t, closeSideNav)}
                    <img className="sidenav__logo right" src={logo} />
                </ul>
            </div>
        );
    }
}

export default {
    component: withNamespaces()(Navbar),
    class: Navbar
};
