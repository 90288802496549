import * as React from "react";
import "./App.scss";

import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import MobileApp from "./pages/mobile-app/mobile-app";
import Publicity from "./pages/publicity/publicity";
import Transporters from "./pages/transporters/transporters";

import { WithNamespaces, withNamespaces } from "react-i18next";

export interface AppProps extends WithNamespaces {}

export const PAGES = {
    MAIN: "/",
    TRANSPORTERS: "/transporters/",
    PUBLICITY: "/publicity/",
    MOBILE_APP: "/mobile-app/"
};

export function triggerSupportClick() {
    const supportPopOver = document
        .getElementById("freshwidget-button")
        .getElementsByClassName("freshwidget-theme")[0] as HTMLElement;
    supportPopOver.click();
}
class App extends React.Component<AppProps, undefined> {
    render() {
        const { t } = this.props;
        return (
            <Router>
                <ScrollToTop>
                    <div className="app">
                        <Navbar.component />
                        <div className="app__page z-depth-3">
                            <Route path={PAGES.MAIN} exact component={Home} />
                            <Route path={PAGES.TRANSPORTERS} component={Transporters} />
                            <Route path={PAGES.PUBLICITY} component={Publicity} />
                            <Route path={PAGES.MOBILE_APP} component={MobileApp} />
                        </div>
                        <Footer />
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default withNamespaces()(App);
