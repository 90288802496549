import * as React from "react";
import "./home.scss";
import { Link } from "react-router-dom";
import { PAGES } from "../../App";

import Background from "-!svg-react-loader!../../assets/img/home-background.svg";

import * as cssapp from '../../assets/img/cssapp.png'
import * as parentapp from '../../assets/img/parentapp.png'
import * as carrierapp from '../../assets/img/carrierapp.png'

import { withNamespaces, WithNamespaces } from "react-i18next";

export interface HomeProps extends WithNamespaces { }
class Home extends React.Component<HomeProps, undefined> {
    render() {
        const { t } = this.props;
        return (
            <section className="home">
                <div className="background">
                    <div className="svg-container">
                        <Background />
                    </div>
                    <div className="container">
                        <div className="row selections">
                            <div className="col s12 m12 xl12 entryTitle">
                                <p>{t("home-title-revolution")}</p>
                            </div>
                            <article className="col s12 m6 xl4">
                                {/* <Link to={PAGES.MOBILE_APP}> */}
                                <div className="card medium z-depth-3">
                                    <div className="card-image card-image__users">
                                        <span className="card-title">{t("home-card-users-title")}</span>
                                    </div>
                                    <div className="card-content black-text">
                                        <p>
                                            {t("home-card-users-1")}
                                            <span className="bold">{t("home-card-users-2")}</span>{t("home-card-users-3")}

                                            <img className="card-app" src={cssapp} />

                                        </p>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </article>
                            <article className="col s12 m6 xl4">
                                {/* <Link to={PAGES.PUBLICITY}> */}
                                <div className="card medium z-depth-3">
                                    <div className="card-image card-image__marketing-ads">
                                        <span className="card-title">{t("home-card-ads-title")}</span>
                                    </div>
                                    <div className="card-content black-text">
                                        <p>
                                            {t("home-card-ads-1")}
                                            <span className="bold">{t("home-card-ads-2")}</span>

                                            <img className="card-app" src={parentapp} />

                                        </p>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </article>
                            <article className="col s12 m6 xl4">
                                {/* <Link to={PAGES.TRANSPORTERS}> */}
                                <div className="card medium z-depth-3">
                                    <div className="card-image card-image__transporter">
                                        <span className="card-title">{t("home-card-transport-title")}</span>
                                    </div>
                                    <div className="card-content black-text">
                                        <p>
                                            {t("home-card-transport-1")}
                                            <span className="bold">{t("home-card-transport-2")}</span>
                                            {t("home-card-transport-3")}
                                            <span className="bold">{t("home-card-transport-4")}</span>

                                            <img className="card-app" src={carrierapp} />

                                        </p>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </article>
                        </div>
                    </div>
                </div >
            </section >
        );
    }
}

export default withNamespaces()(Home);
