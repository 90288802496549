import * as React from "react";
import "./transporters.scss";

import * as mainView from "../../assets/img/transporters/main-view.png";
import * as mainViewMobile from "../../assets/img/transporters/mobile/main-view.png";
import * as report from "../../assets/img/transporters/report.png";
import * as tracking from "../../assets/img/transporters/tracking.png";
import * as trackingMobile1 from "../../assets/img/transporters/mobile/tracking1.png";
import * as trackingMobile2 from "../../assets/img/transporters/mobile/tracking2.png";
import { triggerSupportClick } from "../../App";

import { withNamespaces, WithNamespaces } from "react-i18next";

export interface TransportersProps extends WithNamespaces {}

class Transporters extends React.Component<TransportersProps, undefined> {
    render() {
        const { t } = this.props;

        return (
            <div className="transporters center-align">
                <div className="header">
                    <div className="container">
                        <div className="bottom-spacing">
                            <h3>{t("transporter-main-title")}</h3>
                            <p>{t("transporter-main-subtitle")}</p>
                        </div>
                        <img className="header__image hide-on-small-only z-depth-3" src={mainView} />
                        <img
                            className="header__image header__image--mobile hide-on-med-and-up z-depth-3"
                            src={mainViewMobile}
                        />
                    </div>
                    <div className="header__bottom-gradient" />
                </div>
                <div className="features">
                    <div className="container">
                        <h3>{t("transporter-features-title")}</h3>
                        <p>{t("transporter-features-subtitle")}</p>
                        <div className="features__list">
                            <div className="feature">
                                <i className="feature__icon material-icons">alarm_on</i>
                                <h5 className="feature__title">{t("transporter-features-1-title")}</h5>
                                <p className="feature__description">{t("transporter-features-1-description")}</p>
                            </div>
                            <div className="feature">
                                <i className="feature__icon material-icons">departure_board</i>
                                <h5 className="feature__title">{t("transporter-features-2-title")}</h5>
                                <p className="feature__description">
                                    {t("transporter-features-2-description")}{" "}
                                    <b>{t("transporter-features-2-description-1")}</b>{" "}
                                    {t("transporter-features-2-description-2")}
                                </p>
                            </div>
                            <div className="feature">
                                <i className="feature__icon material-icons">flight_takeoff</i>
                                <h5 className="feature__title">{t("transporter-features-3-title")}</h5>
                                <p className="feature__description">
                                    {t("transporter-features-3-description")}{" "}
                                    <b>{t("transporter-features-3-description-1")}</b>{" "}
                                    {t("transporter-features-3-description-2")}
                                </p>
                            </div>
                            <div className="feature">
                                <i className="feature__icon material-icons">build</i>
                                <h5 className="feature__title">{t("transporter-features-4-title")}</h5>
                                <p className="feature__description">
                                    {t("transporter-features-4-description")}{" "}
                                    <b>{t("transporter-features-4-description-1")}</b>.
                                </p>
                            </div>
                            <div className="feature">
                                <i className="feature__icon material-icons">security</i>
                                <h5 className="feature__title">{t("transporter-features-5-title")}</h5>
                                <p className="feature__description">
                                    {t("transporter-features-5-description")}{" "}
                                    <b>{t("transporter-features-5-description-1")}</b>.
                                </p>
                            </div>
                            <div className="feature">
                                <i className="feature__icon material-icons">mobile_friendly</i>
                                <h5 className="feature__title">{t("transporter-features-6-title")}</h5>
                                <p className="feature__description">
                                    {t("transporter-features-6-description")}{" "}
                                    <b>{t("transporter-features-6-description-1")}</b>{" "}
                                    {t("transporter-features-6-description-2")}{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-us">
                    <div className="container">
                        <h3>{t("transporter-contact-title")}</h3>
                        <p>
                            {t("transporter-contact-body-1")}
                            <br />
                            {t("transporter-contact-body-2")}
                        </p>
                        <div className="contactOptions">
                            <div className="btn btn-large waves-effect waves-light" onClick={triggerSupportClick}>
                                <a>
                                    <i className="material-icons left">email</i>
                                    {t("contact-us")}
                                </a>
                            </div>
                            <div className="btn btn-large waves-effect waves-light">
                                <a href="tel:1-888-355-9946">
                                    <i className="material-icons left">phone</i>1-888-355-WYHN
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tracking">
                    <div className="container">
                        <div className="bottom-spacing">
                            <h3>{t("transporter-tracking-title")}</h3>
                            <p>{t("transporter-tracking-description")}</p>
                        </div>
                        <img className="tracking__image hide-on-small-only z-depth-3" src={tracking} />
                        <img
                            className="tracking__image tracking__image--mobile hide-on-med-and-up z-depth-3"
                            src={trackingMobile2}
                        />
                        <img
                            className="tracking__image tracking__image--mobile hide-on-med-and-up z-depth-3"
                            src={trackingMobile1}
                        />
                    </div>
                </div>
                <div className="reporting">
                    <div className="container">
                        <div className="bottom-spacing">
                            <h3>{t("transporter-reporting-title")}</h3>
                            <p>{t("transporter-reporting-description")}</p>
                        </div>
                        <img className="reporting__image z-depth-3" src={report} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Transporters);
