import { Locale } from "./index";

export const en: Locale = {
    "ads-features-1": "Unique and targeted traffic",
    "ads-features-2": "Constantly expanding network",
    "ads-features-3": "Active and engaged users",
    "ads-features-title": "Our advertising benefits",
    "ads-main-subtitle":
        "Our advertising system is simple and effective. It gives your company a guaranteed visibility and allows you to reach a larger public.",
    "ads-main-title": "Stand out with our digital network",
    "contact-us": "Contact Us",
    "footer-copyright": `${new Date().getFullYear()} Wyhn Enterprises. All rights reserved.`,
    "footer-video": "https://www.youtube.com/embed/aU8H7LxJPmI",
    "footer-goal":
        "Wyhn brings School Transportation into the 21st century",
    "footer-product-title": "Products",
    "footer-help-title": "Support",
    "footer-more-questions-title": "You have more questions?",
    "footer-network-status": "Network status",
    "footer-support": "Support",
    "home-card-ads-1": "For Parents, Wyhn provide privacy focused tools for sharing information about their children ",
    "home-card-ads-2": "immediately and continuously.\n\n",
    "home-card-ads-action": "Display",
    "home-card-ads-title": "Parents",
    "home-card-transport-1": "For Carriers, it’s a smart fleet management system enabling ",
    "home-card-transport-2": "operations optimization ",
    "home-card-transport-3": "and solutions to improve the ",
    "home-card-transport-4": "recurring school bus driver shortage.\n\n",
    "home-card-transport-action": "Discover",
    "home-card-transport-title": "Carriers",
    "home-card-users-1": "Wyhn offers School Organizations a",
    "home-card-users-2": " centralized platform",
    "home-card-users-3": ", supporting all current and future management and communications needs.\n\n",
    "home-card-users-action": "Download",
    "home-card-users-title": "School Organizations",
    "home-title-revolution": " Wyhn brings school transportation into the 21st century",
    "navbar-link-ads": "Advertisers",
    "navbar-link-transporter": "Transporter",
    "navbar-link-users": "Users",
    "transporter-contact-body-1": "Explain us your needs and our team will be happy to propose you",
    "transporter-contact-body-2": "a customized solution adapted to your company.",
    "transporter-contact-title": "Try our solution for free",
    "transporter-features-1-description":
        "The system recovers the GPS positions of your entire fleet at the fastest interval in the market.",
    "transporter-features-1-title": "Real time",
    "transporter-features-2-description-1": "automatically detects active vehicles",
    "transporter-features-2-description-2":
        "and assigns them to the right trajectory according to the drivers' assignment schedule.",
    "transporter-features-2-description": "Once configured, the system",
    "transporter-features-2-title": "Automatic",
    "transporter-features-3-description-1": "to adapt whatever the size of your network",
    "transporter-features-3-description-2":
        "or the number of users. This provides an experience always fast and without waiting.",
    "transporter-features-3-description": "The system architecture is able",
    "transporter-features-3-title": "Fast",
    "transporter-features-4-description-1": "your organization's needs",
    "transporter-features-4-description": "Vehicles' tracking settings are customizable to",
    "transporter-features-4-title": "Customizable",
    "transporter-features-5-description-1": "robust and secure system",
    "transporter-features-5-description": "Several measures have been put in place to ensure a",
    "transporter-features-5-title": "Secure",
    "transporter-features-6-description-1": "is suitable",
    "transporter-features-6-description-2": "for both desktops and mobile phones.",
    "transporter-features-6-description": "The web application",
    "transporter-features-6-title": "Dynamic",
    "transporter-features-subtitle": "Wyhn offers a state-of-the-art applications system.",
    "transporter-features-title": "The best features",
    "transporter-main-subtitle":
        "Wyhn's comprehensive system makes it easier and faster to manage your assets on the road.",
    "transporter-main-title": "Track your fleet in real time",
    "transporter-reporting-description":
        "Each trajectory performed is saved to allow the generation of reports and statistics on your network.",
    "transporter-reporting-title": "The complete history of the network",
    "transporter-tracking-description":
        "The system displays the advancements of each vehicle on their assigned bus line relative to the schedule and indicates their status.",
    "transporter-tracking-title": "Follow the progress of a vehicle",
    "user-app-download-title": "Available on Android and IOS.",
    "coming-soon-android-ios": "The app is available for Android and iOS.",
    "user-app-schedule-1": "View the list of all available lines on the network in a single click.",
    "user-app-schedule-2": "By clicking on a bus line, the application gives the passage times at each stop.",
    "user-app-schedule-title": "See available trips.",
    "user-app-showcase-1": "Track movements in real time.",
    "user-app-showcase-2": "The application allows you to see the buses near you with great precision.",
    "user-app-title-1": "Always have the right time",
    "user-app-title-2": "on trips",
    "user-app-title-3": "from the network."
};
