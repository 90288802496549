import * as React from "react";
import "./mobile-app.scss";

import * as s9 from "../../assets/img/mobile-app/s9.png";
import * as wax from "../../assets/img/mobile-app/waxLogo.png";
import * as s92 from "../../assets/img/mobile-app/s9-2-plane.png";
import * as iphone from "../../assets/img/mobile-app/iphone.png";
import * as apple from "../../assets/img/apple.svg";
import * as bus from "../../assets/img/mobile-app/bus.svg";

import { withNamespaces, WithNamespaces } from "react-i18next";

declare var M: any;

export interface MobileAppProps extends WithNamespaces {}

class MobileApp extends React.Component<MobileAppProps, undefined> {
    renderDownloadButtons() {
        return (
            <div className="download-buttons">
                <a
                    className="waves-effect waves-light btn"
                    href="https://play.google.com/store/apps/details?id=com.wyhn"
                >
                    <i className="material-icons right">android</i>
                    Android
                </a>
                <a
                    className="waves-effect waves-light btn"
                    href="https://itunes.apple.com/us/app/wax/id1455543659?ls=1&mt</a>=8"
                >
                    <img className="apple-icon right" src={apple} />
                    Apple
                </a>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="showcase">
                    <div className="row container">
                        <div className="col s12 m8">
                            <h4 className="showcase__text">
                                {t("user-app-title-1")}
                                <br />
                                {t("user-app-title-2")}
                                <br />
                                {t("user-app-title-3")}
                            </h4>
                            {this.renderDownloadButtons()}
                            <div className="col s12 m4 showcase__mobile-container hide-on-small-only">
                                <img className="logo" src={wax} />
                            </div>
                        </div>
                        <div className="col s12 m4 showcase__mobile-container hide-on-small-only">
                            <img className="showcase__mobile right" src={s9} />
                        </div>
                    </div>
                </div>
                <div className="section bus">
                    <div className="row container">
                        <div className="col s12 m6">
                            <div className="moving-map valign-wrapper">
                                <div className="moving-map__gradient moving-map__gradient--top" />
                                <div className="moving-map__gradient moving-map__gradient--bottom" />
                                <img className="moving-map__marker center-align" src={bus} />
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <h5 className="showcase__text">{t("user-app-showcase-1")}</h5>
                            <p>{t("user-app-showcase-2")}</p>
                        </div>
                    </div>
                </div>
                <div className="section schedules">
                    <div className="container">
                        <div className="row">
                            <div className="col m12 l5">
                                <h5 className="showcase__text">{t("user-app-schedule-title")}</h5>
                                <p>{t("user-app-schedule-1")}</p>
                                <p>{t("user-app-schedule-2")}</p>
                            </div>
                            <div className="col m12 l7">
                                <div>
                                    <div className="schedules__list" />
                                    <img className="schedules__iphone" src={iphone} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section app-download">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h5 className="showcase__text">{t("user-app-download-title")}</h5>
                            </div>
                            {/* <div className="col m12">
                                <p>L'application permet de voir les autobus près de vous avec une grande précision.</p>
                            </div> */}
                            {this.renderDownloadButtons()}

                            <div className="col s12">
                                <img className="app-download__photo" src={s92} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(MobileApp);
