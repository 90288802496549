import * as React from "react";
import "./publicity.scss";

import * as mainAd from "../../assets/img/advertising/mainAd.png";
import * as listAd from "../../assets/img/advertising/listAd.png";
import * as detailAd from "../../assets/img/advertising/detailAd.png";

import * as world from "../../assets/img/advertising/world.svg";
import * as expanding from "../../assets/img/advertising/active.svg";
import * as busstop from "../../assets/img/advertising/busstop.svg";
import { triggerSupportClick } from "../../App";

import { withNamespaces, WithNamespaces } from "react-i18next";

export interface PublicityProps extends WithNamespaces {}

class Publicity extends React.Component<PublicityProps, undefined> {
    render() {
        const { t } = this.props;

        return (
            <div className="publicity">
                <div className="header">
                    <div className="container">
                        <div className="content">
                            <h3 className="header__mainText">{t("ads-main-title")}</h3>
                            <p className="header__subText">{t("ads-main-subtitle")}</p>
                            <div className="contactOptions">
                                <div className="btn btn-large waves-effect waves-light" onClick={triggerSupportClick}>
                                    <a>
                                        <i className="material-icons left">email</i>
                                        {t("contact-us")}
                                    </a>
                                </div>
                                <div className="btn btn-large waves-effect waves-light">
                                    <a href="tel:1-888-355-9946">
                                        <i className="material-icons left">phone</i>1-888-355-WYHN
                                    </a>
                                </div>
                            </div>

                            <div className="ads">
                                <div className="displays">
                                    <div className="row">
                                        <div className="col s12 m6 l4 limited">
                                            <img src={mainAd} />
                                        </div>
                                        <div className="col m6 l4 limited hide-on-small-only">
                                            <img src={listAd} />
                                        </div>
                                        <div className="col l4 limited hide-on-med-and-down">
                                            <img src={detailAd} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="features">
                    <div className="container">
                        <h3>{t("ads-features-title")}</h3>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m4">
                                <img className="features__image" src={world} />
                                <p className="features__description">{t("ads-features-1")}</p>
                            </div>
                            <div className="col s12 m4">
                                <img className="features__image" src={expanding} />
                                <p className="features__description">{t("ads-features-2")}</p>
                            </div>
                            <div className="col s12 m4">
                                <img className="features__image" src={busstop} />
                                <p className="features__description">{t("ads-features-3")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Publicity);
