import { en } from "./en";
import { fr } from "./fr";

// the translations
export default {
    en: {
        translation: en
    },
    fr: {
        translation: fr
    }
};

export type Locale = {
    "ads-features-1": string;
    "ads-features-2": string;
    "ads-features-3": string;
    "ads-features-title": string;
    "ads-main-subtitle": string;
    "ads-main-title": string;
    "contact-us": string;
    "footer-network-status": string;
    "footer-product-title": string;
    "footer-help-title": string;
    "footer-more-questions-title": string;
    "footer-goal": string;
    "footer-copyright": string;
    "footer-video": string;
    "footer-support": string;
    "home-card-ads-1": string;
    "home-card-ads-2": string;
    "home-card-ads-action": string;
    "home-card-ads-title": string;
    "home-card-transport-1": string;
    "home-card-transport-2": string;
    "home-card-transport-3": string;
    "home-card-transport-4": string;
    "home-card-transport-action": string;
    "home-card-transport-title": string;
    "home-card-users-1": string;
    "home-card-users-2": string;
    "home-card-users-3": string;
    "home-card-users-action": string;
    "home-card-users-title": string;
    "home-title-revolution": string;
    "navbar-link-ads": string;
    "navbar-link-transporter": string;
    "navbar-link-users": string;
    "transporter-contact-body-1": string;
    "transporter-contact-body-2": string;
    "transporter-contact-title": string;
    "transporter-features-1-description": string;
    "transporter-features-1-title": string;
    "transporter-features-2-description-1": string;
    "transporter-features-2-description-2": string;
    "transporter-features-2-description": string;
    "transporter-features-2-title": string;
    "transporter-features-3-description-1": string;
    "transporter-features-3-description-2": string;
    "transporter-features-3-description": string;
    "transporter-features-3-title": string;
    "transporter-features-4-description-1": string;
    "transporter-features-4-description": string;
    "transporter-features-4-title": string;
    "transporter-features-5-description-1": string;
    "transporter-features-5-description": string;
    "transporter-features-5-title": string;
    "transporter-features-6-description-1": string;
    "transporter-features-6-description-2": string;
    "transporter-features-6-description": string;
    "transporter-features-6-title": string;
    "transporter-features-subtitle": string;
    "transporter-features-title": string;
    "transporter-main-subtitle": string;
    "transporter-main-title": string;
    "transporter-reporting-description": string;
    "transporter-reporting-title": string;
    "transporter-tracking-description": string;
    "transporter-tracking-title": string;
    "coming-soon-android-ios": string;
    "user-app-download-title": string;
    "user-app-schedule-1": string;
    "user-app-schedule-2": string;
    "user-app-schedule-title": string;
    "user-app-showcase-1": string;
    "user-app-showcase-2": string;
    "user-app-title-1": string;
    "user-app-title-2": string;
    "user-app-title-3": string;
};
