import * as React from "react";
import "./footer.scss";
import * as logo from "../../assets/img/wyhn.svg";
import * as logotxt from '../../assets/img/wyhn_text_ylw.svg'
import * as facebookimg from '../../assets/img/wyhn_facebook.svg'
import * as linkedinimg from '../../assets/img/wyhn_linkedin.svg'
import * as twitterimg from '../../assets/img/wyhn_twitter.svg'
import { triggerSupportClick } from "../../App";

import { withNamespaces, WithNamespaces } from "react-i18next";

export interface FooterProps extends WithNamespaces { }
class Footer extends React.PureComponent<FooterProps, undefined> {
    renderMiscLinks() {
        const { t } = this.props;
        return [
            /* TODO: UNCOMMENT
            <li key="footer-link-1">
                <a href="#">{t("footer-network-status")}</a>
            </li>,
            */
            <li key="footer-link-2">
                <a href="https://wyhn.freshdesk.com/support/home" target="_blank">{t("footer-support")}</a>
            </li>,
            <li onClick={triggerSupportClick} key="footer-link-3">
                <a href="#">{t("contact-us")}</a>
            </li>,
            <li key="footer-link-4">
                <a href="https://www.linkedin.com/company/wyhn" target="_blank"><img className="social-media-img" src={linkedinimg} /></a>{" "}
                <a href="https://twitter.com/WyhnCo" target="_blank"><img className="social-media-img" src={twitterimg} /></a>{" "}
                <a href="https://www.facebook.com/WYHNFB" target="_blank"><img className="social-media-img" src={facebookimg} /></a>
            </li>,
        ];
    }

    render() {
        const { t } = this.props;

        return (
            <footer className="page-footer">
                <section>
                    <div className="container">
                        <iframe width="854" height="480" className="social-media-video"
                            src={t("footer-video")} title="Wyhn" frameBorder="0" allow="accelerometer; clipboard-write; 
                        encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <div className="col s12 m12 xl12 centered">
                            <h3 className="footer-heading">{t("footer-more-questions-title")}</h3>
                            <div className="contactOptions">
                                <div className="btn btn-large waves-effect waves-light" onClick={triggerSupportClick}>
                                    <a>
                                        <i className="material-icons left">email</i>
                                        {t("contact-us")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row page-footer__content">
                        <div className="col l6 s12">
                            <h5 className="footer-header brand valign-wrapper">
                                <img className="brand__logo" src={logo} />
                                <img className="brand__logo_txt" src={logotxt} />
                            </h5>
                            <p className="grey-text text-lighten-4">{t("footer-goal")}</p>
                        </div>
                        <div className="col l2 offset-l2 s6 links"></div>
                        <div className="col l2 s6 links">
                            <h5 className="footer-header">{/* {t("footer-help-title")} */}</h5>
                            <ul>{this.renderMiscLinks()}</ul>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">&copy;{t("footer-copyright")}</div>
                </div>
            </footer >
        );
    }
}

export default withNamespaces()(Footer);
