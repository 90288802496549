import * as React from "react";

import { withRouter } from "react-router-dom";

import { withNamespaces, WithNamespaces } from "react-i18next";

export interface ScrollToTopProps extends WithNamespaces {
    location?: any; // RouteComponentProps
}

@(withRouter as any)
class ScrollToTop extends React.Component<ScrollToTopProps, {}> {
    componentDidUpdate(prevProps: ScrollToTopProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withNamespaces()(ScrollToTop);
