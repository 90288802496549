import { Locale } from "./index";

export const fr: Locale = {
    "ads-features-1": "Achalandage unique et ciblé",
    "ads-features-2": "Réseau en expansion constante",
    "ads-features-3": "Utilisateurs actifs et engagés",
    "ads-features-title": "Nos avantages publicitaires",
    "ads-main-subtitle":
        "Notre système publicitaire est simple et efficace. Il donne à votre entreprise une visibilité garantie et vous permet d'atteindre une plus grande clientèle.",
    "ads-main-title": "Démarquez-vous avec notre réseau numérique",
    "contact-us": "Communiquez avec nous",
    "footer-copyright": `${new Date().getFullYear()} Les Entreprises Wyhn. Tous droits réservés.`,
    "footer-video": "https://www.youtube.com/embed/zf4Ny4yDZR8",
    "footer-goal":
        "Wyhn offre au transport scolaire une technologie adaptée au 21e siècle",
    "footer-product-title": "Produits",
    "footer-help-title": "Support",
    "footer-more-questions-title": "Vous avez d'autres questions?",
    "footer-network-status": "Status du réseau",
    "footer-support": "Support",
    "home-card-ads-1": "Pour les parents, Wyhn fournit des outils axés sur la confidentialité permettant le partage d’informations concernant leurs enfants ",
    "home-card-ads-2": "de façon immédiate et en continue.",
    "home-card-ads-action": "Afficher",
    "home-card-ads-title": "Parents",
    "home-card-transport-1": "Pour les transporteurs, c’est un système de gestion de flotte intelligent permettant une ",
    "home-card-transport-2": "optimisation des opérations ",
    "home-card-transport-3": "et offrant des solutions pour améliorer la",
    "home-card-transport-4": " pénurie récurrente de chauffeurs d'autobus scolaires.",
    "home-card-transport-action": "Découvrir",
    "home-card-transport-title": "Transporteurs",
    "home-card-users-1": "Wyhn offre aux organisations scolaires une ",
    "home-card-users-2": "plateforme centralisée",
    "home-card-users-3": ", prenant en charge la totalité des besoins actuels et futur de gestion et de communications.\n\n",
    "home-card-users-action": "Téléchargez",
    "home-card-users-title": "Organisations Scolaires",
    "home-title-revolution": "Wyhn offre au transport scolaire une technologie adaptée au 21e siècle",
    "navbar-link-ads": "Annonceurs",
    "navbar-link-transporter": "Transporteur",
    "navbar-link-users": "Utilisateurs",
    "transporter-contact-body-1": "Expliquez-nous vos besoins et notre équipe se fera un plaisir de vous proposer",
    "transporter-contact-body-2": "une solution personnalisée et adaptée à votre entreprise.",
    "transporter-contact-title": "Faites l'essai de notre solution gratuitement",
    "transporter-features-1-description":
        "Le système récupère les positions GPS de votre flotte complète à l'intervalle la plus rapide dans le marché.",
    "transporter-features-1-title": "Temps réel",
    "transporter-features-2-description-1": "détecte automatiquement les véhicules actifs",
    "transporter-features-2-description-2":
        "et les assigne à la bonne trajectoire selon l'horaire d'assignation des chauffeurs.",
    "transporter-features-2-description": "Une fois configuré, le système",
    "transporter-features-2-title": "Automatique",
    "transporter-features-3-description-1": "s'adapter quelque soit la taille de votre réseau",
    "transporter-features-3-description-2":
        "ou le nombre d'utilisateurs. Cela procure une expérience toujours rapide et sans attente.",
    "transporter-features-3-description": "L'architecture du système est en mesure de",
    "transporter-features-3-title": "Rapide",
    "transporter-features-4-description-1": "besoins de votre organisation",
    "transporter-features-4-description": "Les paramètres de suivi des véhicules sont personnalisables en fonction des",
    "transporter-features-4-title": "Personnalisable",
    "transporter-features-5-description-1": "système robuste et sécuritaire",
    "transporter-features-5-description": "Plusieurs mesures ont été mises en place afin de garantir un",
    "transporter-features-5-title": "Sécuritaire",
    "transporter-features-6-description-1": "s'adapte",
    "transporter-features-6-description-2": "autant aux ordinateurs de bureau qu'aux téléphones mobiles.",
    "transporter-features-6-description": "L'application Web",
    "transporter-features-6-title": "Dynamique",
    "transporter-features-subtitle": "Wyhn propose un système d'applications à la fine pointe de la technologie.",
    "transporter-features-title": "Les meilleures fonctionnalités",
    "transporter-main-subtitle":
        "Le système complet offert par Wyhn permet de gérer plus facilement et plus rapidement vos actifs sur la route.",
    "transporter-main-title": "Suivez votre flotte en temps réel",
    "transporter-reporting-description":
        "Chaque trajectoire effectuée est enregistrée afin de permettre la génération de rapports et de statistiques sur votre réseau.",
    "transporter-reporting-title": "L'historique complet du réseau",
    "transporter-tracking-description":
        "Le système affiche les avancements de chaque véhicule sur leur trajectoire assignée par rapport à la planification et indique leur état.",
    "transporter-tracking-title": "Suivez le progrès d'un véhicule",
    "coming-soon-android-ios": "L'application est disponible sur Android et IOS.",
    "user-app-download-title": "Disponible sur Android et IOS.",
    "user-app-schedule-1": "Afficher la liste des trajectoires disponibles sur le réseau en un seul clic.",
    "user-app-schedule-2": "En cliquant sur une trajectoire, l'application donne les temps de passage à chaque arrêt.",
    "user-app-schedule-title": "Voir les trajets disponibles.",
    "user-app-showcase-1": "Suivre les déplacements en temps réel.",
    "user-app-showcase-2": "L'application permet de voir les autobus près de vous avec une grande précision.",
    "user-app-title-1": "Avoir toujours l'heure juste",
    "user-app-title-2": "sur les déplacements",
    "user-app-title-3": "du réseau."
};
